<template>
  <div class="hero_section bg-hero w-full min-h-screen under_top_bar">
    <Meta name="description" content="Do You Want To Get The Latest Insight On Cryptocurrency And Gift Cards? Exclusive Updates On How To Trade Bitcoin, Swap Gift Cards An Pay Bills - Hurry And Start Trading Now!" />
    <section ref="CONTAINER" class="container lg:grid mb-[2rem] items-center lg:grid-cols-2 md:py-14 md:gap-0 lg:gap-8">
      <div
          class="mx-auto max-w-md pt-12 md:pt-0 md:px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex w-full">
        <div class="lg:py-28">
          <h1 class="hero_section_head_text mt-4 text-4xl tracking-[-0.03em] font-bold text-header sm:mt-5 sm:text-5xl lg:mt-6 xl:text-[56px] font-heading">
            <span class="hero_text font-heading leading-[3.25rem] block">Trade Crypto in</span>
            <span class="hero_text font-heading leading-[3.25rem] block">
              another
              <span class="gradient-text">Dimension</span>
            </span>
          </h1>
          <p
              class="mt-[.625rem] text-base text-[#292A2D] sm:mt-5 sm:text-xl lg:text-base sm:leading-[28px] max-w-[25.9375rem] xs:max-w-[25rem] mx-0 sm:mx-auto lg:mx-0">
            Simplify your Crypto journey: Buy, sell and swap cryptocurrencies with more possibilities
          </p>
          <div class="hero_text row-col sm:items-center lg:items-start mt-[.625rem] gap-2">
            <p class="font-bold text-[15px] font-body text-content-5">Supports:</p>
            <div class="flex">
              <img v-for="(country, index) in countries" :key="index" :alt="country.alt" :src="country.src" height="32"
                   width="32" class="-ml-2 first:ml-0"/>
              <div class="h-8 w-8 bg-primary-1 flex justify-center items-center rounded-full text-[11px] text-white -ml-2">20+</div>
            </div>
          </div>
          <div class="store_section mt-10 sm:mt-[2rem]">
            <div class="sm:max-w-xl mx-auto lg:flex lg:mx-0 h-full gap-x-5">
              <StoreButtonsContainer class="w-full" :md-hidden="true"/>
              <div class="hidden relative lg:flex">
                <div class="relative">
                  <QrCode2 />
                </div>
                <div class="flex absolute left-[110%] -bottom-[3.5rem] items-end gap-x-2"
                     data-aos="fade-up-left">
                  <div class="pb-4 h-full">
                    <svg fill="none" height="45" viewBox="0 0 97 45" width="97"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M67.9202 44.7162C44.8458 44.262 24.4367 30.8502 8.05785 15.666C7.216 14.8857 6.30684 14.1397 5.44339 13.4013C5.59449 15.7802 8.2077 34.7529 5.13992 35.2718C3.34698 35.575 3.0524 33.1149 2.9178 32.1316C1.94769 25.0216 1.26709 18.5384 0.864571 11.3535C0.561096 5.9449 5.12595 4.76499 9.75936 3.78933C16.8333 2.29984 17.6383 2.57895 25.0259 1.30006C26.2436 1.08945 27.4715 0.77861 28.6689 0.702488C30.077 0.613674 31.9144 1.08565 32.0566 2.67411C32.2179 4.47445 30.7272 5.20142 29.2022 5.38665C28.8835 5.42472 28.566 5.47422 28.2498 5.53131C24.777 6.15933 8.54037 8.18805 8.64322 9.37051C8.67751 9.76889 8.9378 10.1051 9.19048 10.4147C16.479 19.3479 26.1319 26.2929 36.0844 31.9845C42.7812 35.8148 50.109 38.5362 57.6794 40.0295C63.7629 41.2298 70.0534 41.599 76.2144 40.8035C82.821 39.9509 88.2379 37.4337 94.1208 34.4877C97.5072 32.7914 96.7149 37.4083 95.0959 38.8115C93.317 40.3543 90.7724 40.8974 88.5934 41.6155C81.6985 43.884 74.7643 44.8507 67.9202 44.7162Z"
                          fill="#FB8E0B"/>
                    </svg>
                  </div>
                  <p class="inline-flex text-body font-bold max-w-[6.25rem] font-heading">
                    Scan to Download Apex App
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- animate__animated animate__shakeY animate__infinite infinite animate__slower 6s -->
      <div class="hero_images max-w-[33rem] mx-auto pointer-events-none select-none py-10">
        <img src="/images/hero_section/main.png" alt="app btc swap mockup" class="w-full"/>
      </div>
    </section>
    <CryptoListing/>
  </div>
</template>

<script setup>
import CryptoListing from './CryptoListing.vue';
import {gsap} from "gsap";
import QrCode2 from "~/components/QrCode2.vue";

const countries = [
  {
    src: '/images/crypto_logo/BTC.png',
    alt: 'bitcoin logo',
  },
  {
    src: '/images/crypto_logo/USDT.png',
    alt: 'usdt logo',
  },
  {
    src: '/images/crypto_logo/ETH.png',
    alt: 'ethereum logo',
  },
  {
    src: '/images/crypto_logo/USD.png',
    alt: 'nigeria logo',
  },
];
const tl = gsap.timeline({paused: true});
const CONTAINER = ref(null);
const animate = () => {
  tl.from(CONTAINER.value.querySelector(".hero_text"), {
    duration: .5,
    y: "150",
    opacity: 0,
    stagger: .5,
    ease: "sine",
  })
      .from(CONTAINER.value.querySelector(".hero_section p"), {
        duration: .5,
        x: "100",
        opacity: 0,
        stagger: 0.1,
        ease: "back",
      })
      .from(CONTAINER.value.querySelector(".store_section"), {
        duration: .5,
        y: "100",
        opacity: 0,
        stagger: 0.1,
        ease: "back",
      })
      .from(CONTAINER.value.querySelector(".hero_images"), {
        duration: .5,
        scale: "0",
        opacity: 0,
        stagger: 0.1,
        ease: "back",
      });
  tl.play();
};

onMounted(() => {
  animate();

});
</script>

<style lang="scss" scoped>
//.hero_section {
//  @apply bg-hero w-full;
//}
//
//.hero_section .container {
//  @apply lg:grid mb-[2rem] lg:grid-cols-2 md:gap-0 lg:gap-8;
//}
//
//.hero_section .hero_description_container {
//  @apply flex flex-col gap-4 my-[7rem] items-start;
//  position: relative;
//  animation-name: content;
//  animation-duration: 3s;
//  animation-fill-mode: forwards;
//}
//
//.hero_section_head_text {
//  @apply mt-4 text-4xl tracking-[-0.03em] font-bold text-header sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl font-heading;
//  .hero_text {
//    @apply font-heading;
//  }
//}
//
//.hero_section .hero_description_container .sub_text {
//  @apply max-w-[420px] text-content-4 font-body leading-[28px] text-base;
//}

.hero_section .hero_images {
  //-webkit-animation-duration: 28s;
  //animation-duration: 28s;
  //-webkit-animation-duration: 28s;
  //animation-duration: 28s;
  //@apply flex relative min-h-[30rem] sm:min-h-[35rem] md:min-h-[40rem] lg:min-h-[45rem] max-w-[33rem] mx-auto w-full;
}

//.hero_section .hero_images .image_mockup {
//  @apply absolute;
//}

//.hero_section .hero_images .image_mockup.left {
//  @apply w-[70%] left-[1rem] top-[.5rem] right-[17px] md:w-[78%] md:left-[0px] z-[5];
//
//  //&::after {
//  //  @apply absolute block w-10 h-10 bg-primary-1;
//  //  content: "";
//  //}
//}

//.hero_section .hero_images .image_mockup.right {
//  //@apply w-[55%] right-[1rem] md:w-[62%] md:right-0;
//}
</style>